// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["elGT4XbKU", "Yehcmu_xH"];

const serializationHash = "framer-5GDaC"

const variantClassNames = {elGT4XbKU: "framer-v-17fhosn", Yehcmu_xH: "framer-v-7p5hz4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Active: "Yehcmu_xH", Default: "elGT4XbKU"}

const getProps = ({fontSize, height, id, link, title, width, ...props}) => { return {...props, EEw87Ujzu: link ?? props.EEw87Ujzu, G7Im_O5_e: fontSize ?? props.G7Im_O5_e ?? 16, I5HD5NipG: title ?? props.I5HD5NipG ?? "Nav Item", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "elGT4XbKU"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;fontSize?: number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, I5HD5NipG, EEw87Ujzu, G7Im_O5_e, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "elGT4XbKU", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5GDaC", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={EEw87Ujzu} smoothScroll><motion.a {...restProps} className={`${cx("framer-17fhosn", className)} framer-elkyxh`} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"elGT4XbKU"} ref={ref ?? ref1} style={{backgroundColor: "rgba(235, 235, 235, 0)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} variants={{Yehcmu_xH: {backgroundColor: "rgb(235, 235, 235)"}}} {...addPropertyOverrides({Yehcmu_xH: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS1yZWd1bGFy", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif", "--framer-font-size": "calc(var(--variable-reference-G7Im_O5_e-LRNQPLXpQ) * 1px)"}}>Nav Item</motion.p></React.Fragment>} className={"framer-1tq0mcs"} fonts={["GF;Manrope-regular"]} layoutDependency={layoutDependency} layoutId={"KLX4oYuz8"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px", "--variable-reference-G7Im_O5_e-LRNQPLXpQ": G7Im_O5_e}} text={I5HD5NipG} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5GDaC [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5GDaC .framer-elkyxh { display: block; }", ".framer-5GDaC .framer-17fhosn { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 16px 16px 16px 16px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-5GDaC .framer-1tq0mcs { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5GDaC .framer-17fhosn { gap: 0px; } .framer-5GDaC .framer-17fhosn > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5GDaC .framer-17fhosn > :first-child { margin-left: 0px; } .framer-5GDaC .framer-17fhosn > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 95
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"Yehcmu_xH":{"layout":["auto","auto"]}}}
 * @framerVariables {"I5HD5NipG":"title","EEw87Ujzu":"link","G7Im_O5_e":"fontSize"}
 * @framerImmutableVariables true
 */
const FramerLRNQPLXpQ: React.ComponentType<Props> = withCSS(Component, css, "framer-5GDaC") as typeof Component;
export default FramerLRNQPLXpQ;

FramerLRNQPLXpQ.displayName = "Nav Item";

FramerLRNQPLXpQ.defaultProps = {height: 51, width: 95};

addPropertyControls(FramerLRNQPLXpQ, {variant: {options: ["elGT4XbKU", "Yehcmu_xH"], optionTitles: ["Default", "Active"], title: "Variant", type: ControlType.Enum}, I5HD5NipG: {defaultValue: "Nav Item", displayTextArea: false, title: "Title", type: ControlType.String}, EEw87Ujzu: {title: "Link", type: ControlType.Link}, G7Im_O5_e: {defaultValue: 16, title: "Font Size", type: ControlType.Number}} as any)

addFonts(FramerLRNQPLXpQ, [{family: "Manrope", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_A87jxeN7B.woff2", weight: "400"}])